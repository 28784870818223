import React from 'react';
import { Subscribe } from 'unstated';
import { AuthState } from '../state';

class HandleError extends React.Component {
  componentDidMount = async () => {
    await this.props.handleLogout();
    window.location.href = '/';
  };
  render() {
    return null;
  }
}

class ErrorBoundary extends React.Component {
  state = { error: null };
  componentDidCatch(error, info) {
    window.alert(error.message);
    this.setState({ error });
  }
  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (!error) return children;

    return (
      <Subscribe to={[AuthState]}>
        {({ handleLogout }) => {
          return <HandleError handleLogout={handleLogout} />;
        }}
      </Subscribe>
    );
  }
}
export { ErrorBoundary };
