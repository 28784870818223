import React, { Fragment } from 'react';
import Select, { components } from 'react-select';
import { TextFieldHelperText } from '@rmwc/textfield';
import { ErrorMessage } from 'formik';
import { Query } from 'react-apollo';
import { plural } from 'pluralize';
import styled from 'styled-components';
import { orderBy } from 'lodash';
import { buildURL } from 'react-imgix';

import { remote } from '../../../graphs';
import Text from '../Text';

const ImageOption = styled(components.Option)`
  &&& {
    display: flex;
    align-items: center;
    height: 96px;
    img {
      margin-right: 1rem;
      object-fit: cover;
      width: 96px;
      height: 100%;
      border-radius: 0.25rem;
    }
  }
`;

const ImageSingleValue = styled(components.SingleValue)`
  &&& {
    display: flex;
    align-items: center;
    height: 96px;
    img {
      margin-right: 1rem;
      object-fit: cover;
      width: 96px;
      height: 100%;
      border-radius: 0.25rem;
    }
  }
`;

const FormikReferenceField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  help,
  label,
  referenceType,
  referenceLabel,
  referencePath,
  widget,
  ...props
}) => {
  let where = {};
  if (widget === 'ImageSelect') {
    where = { type_contains: 'image' };
  }
  return (
    <Query
      query={remote.query[`${plural(referenceType)}Connection`]}
      fetchPolicy="cache-and-network"
      variables={{ where, orderBy: 'createdAt_DESC' }}>
      {({ loading, data }) => {
        if (loading) {
          return (
            <>
              <Text use="caption">{label}</Text>
              <Select
                isLoading={loading}
                placeholder={`Loading ${plural(label)}...`}
              />
            </>
          );
        }

        const name = `${plural(referenceType)}Connection`;

        let options = data[name].edges.reduce((acc, { node }) => {
          const label =
            node[referenceLabel] || node.name || node.title || node.id;
          acc.push({
            label: node.slug ? `${label} (${node.slug})` : label,
            value: node.id,
            data: node,
          });
          return acc;
        }, []);
        options = orderBy(options, ['label'], ['asc']);

        const value =
          field.value && !field.value.disconnect
            ? {
                label: field.value.slug
                  ? `${field.value.title || field.value.name} (${
                      field.value.slug
                    })`
                  : field.value.title || field.value.name,
                value: field.value.id,
                data: field.value,
              }
            : null;

        const Option = props => {
          const { data, label } = props.data;
          if (data.type && data.type.includes('image')) {
            return (
              <ImageOption {...props}>
                <img
                  src={buildURL(data.src, { w: 96, h: 96, auto: 'format' })}
                  alt={label}
                />
                <Text tag="span">{label}</Text>
              </ImageOption>
            );
          }
          return <components.Option {...props} />;
        };

        const SingleValue = ({ children, ...props }) => {
          const { data, label } = props.data;
          return (
            <ImageSingleValue {...props}>
              {data.src && (
                <img
                  src={buildURL(data.src, { w: 96, h: 96, auto: 'format' })}
                  alt={label}
                />
              )}
              <Text tag="span">{label}</Text>
            </ImageSingleValue>
          );
        };

        return (
          <Fragment>
            <Text use="caption">{label}</Text>
            <Select
              {...field}
              {...props}
              isSearchable
              isClearable
              components={{ Option, SingleValue }}
              name={field.name}
              value={value}
              placeholder={`Select ${label}...`}
              onChange={value =>
                setFieldValue(
                  field.name,
                  !!value ? value.data : { disconnect: true }
                )
              }
              options={options}
            />
            {/* {referencePath && (
              <IconButton
                type="button"
                icon="link"
                onClick={() => navigate(referencePath)}
              />
            )} */}
            {help && (
              <TextFieldHelperText persistent>{help}</TextFieldHelperText>
            )}
            <ErrorMessage
              name={field.name}
              component={TextFieldHelperText}
              persistent
              validationMsg
            />
          </Fragment>
        );
      }}
    </Query>
  );
};

export { FormikReferenceField };
